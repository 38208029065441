@import url('https://fonts.googleapis.com/css?family=DM+Sans&display=swap');

#root > * {
  /* overflow-y: auto !important; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Montserrat', sans-serif !important;
}

input {
  /* overflow-y: auto !important; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
