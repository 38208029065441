@import url(https://fonts.googleapis.com/css?family=DM+Sans&display=swap);
#root > * {
  /* overflow-y: auto !important; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Montserrat', sans-serif !important;
}

input {
  /* overflow-y: auto !important; */
  /* font-family: 'DM Sans', sans-serif !important; */
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

  .Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    /*padding: 20px 10px;*/
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  
  .ReactTable .rt-thead.-header {
    box-shadow: none;
    background: #F5F5FD;
  }

  /** to change default padding for the status_colour */
  .react-table__status-colour {
    padding: 2px 3px 2px 7px !important;
  }

.csldatecontainer {
	border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 5px 5% 5px 5%;
    box-sizing: border-box;
}
.react-date-picker__wrapper {
	display: flex;
    width: 190%;
    justify-content: space-between;
}
.react-date-picker__inputGroup {
	padding-top: 7px;
    width: 70%;
    flex-grow: unset;
    display: inline-block;
}
.react-date-picker__button {
	/* display: inline-block !important; */
	float: right !important;
}

.Dropzone {
  /*height: 50px;
  width: 100%;*/
  background-color: #f7f7f7;
  /*border: 2px dashed rgb(187, 186, 186);*/
  /*border-radius: 50%;*/
  /*display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;*/
  font-size: 12px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none ! important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-date-picker__wrapper {
  border: none ! important;
  /* color: #515151 ! important; */
}

/* extra from filechecker */

/* .react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}
.cdate .react-date-picker__inputGroup {
  width: 100% ! important;
} */

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 25000 !important;
}

.csldatecontainer {
	border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 5px 5% 5px 5%;
    box-sizing: border-box;
}
.react-date-picker__wrapper {
	display: flex;
    width: 190%;
    justify-content: space-between;
}
.react-date-picker__inputGroup {
	padding-top: 7px;
    display: inline-block;
}
.react-date-picker__button {
	display: inline-block;
	float: right;
}

