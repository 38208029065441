.csldatecontainer {
	border: 1px solid #DBDBDB;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    padding: 5px 5% 5px 5%;
    box-sizing: border-box;
}
.react-date-picker__wrapper {
	display: flex;
    width: 190%;
    justify-content: space-between;
}
.react-date-picker__inputGroup {
	padding-top: 7px;
    display: inline-block;
}
.react-date-picker__button {
	display: inline-block;
	float: right;
}
